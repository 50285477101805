<template>
    <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="visible">
                <div class="modal-header">
                    <h6 class="modal-title"><i class="bi bi-building-add me-2"></i>Déployer des infrastructures</h6>
                    <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <p>Veuillez Choisir le modèle d'infrastructre à déployer ainsi que l'utilisateur concerné ou le groupe</p>
                    <div class="mb-3">
                        <label for="" class="form-label"><b>Type de déploiement</b></label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="group" v-model="deploymentType" checked> 
                            <label class="form-check-label" for="exampleRadios2">
                                Groupe
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="user" v-model="deploymentType">
                            <label class="form-check-label" for="exampleRadios1">
                                Utilisateur unique
                            </label>
                        </div>
                    </div>
                    <div class="mb-3" v-if="deploymentType == 'user'">
                        <label for="" class="form-label"><b>Utilisateur</b></label>
                        <select name="" id="" class="form-select form-select-sm" v-model="selectedUser" required>
                            <option v-for="user in users" :key="user._id" :value="user._id">{{user.lastName || "??"}} {{user.firstName || "??"}} ({{user.email}})</option>
                        </select>
                    </div>
                    <div class="mb-3" v-else>
                        <label for="" class="form-label"><b>Groupe</b></label>
                        <select name="" id="" class="form-select form-select-sm" v-model="selectedGroup" required>
                            <option v-for="group in groups" :key="group._id" :value="group._id">{{group.name}} [{{group.members.length}} membre(s)]</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label"><b>Modèle</b></label>
                        <select name="" id="" class="form-select form-select-sm" v-model="selectedModel" required>
                            <option v-for="model in models" :key="model._id" :value="model._id">{{model.name}}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label"><b>Nom <small class="text-muted">(sans caractères spéciaux)</small></b></label>
                        <input type="text" class="form-control" v-model="infraName">
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-sm" @click.prevent="close()"><i class="bi bi-x me-2"></i>Annuler</button>
                    <button type="button" class="btn btn-sm" @click.prevent="confirm()" :disabled="(!inputsDisabled || selectedModel === '')"><i class="bi bi-check text-success me-2"></i>Déployer</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, ref, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import * as Infras from "../../../services/Infras";
    import * as Users from "../../../services/Users";
    import * as Groups from "../../../services/Groups";
    import * as Terras from "../../../services/Terras";

    let props = defineProps(["modalId","show","models"]);
    let emits = defineEmits(["close","confirm"]);

    let loaded = ref(false);

    let deploymentType = ref("group");
    let models = ref([]);
    let users = ref([]);
    let groups = ref([]);

    let infraName = ref("");

    let inputsDisabled = computed( () => (selectedGroup.value !== ""  || selectedUser.value !== "")  );

    let selectedUser = ref("");
    let selectedGroup = ref("");
    let selectedModel = ref("");

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    onMounted( async () => {
        users.value = await Users.getItems();
        groups.value = await Groups.getItems();
        models.value = await Terras.getItems();
    });

    function close(){
        selectedUser.value = "";
        selectedGroup.value = "";
        selectedModel.value = "";
        infraName.value = "";
        emits("close");
    }

    async function confirm(){
        try{

            if(deploymentType.value === "user"){
                let user = await Users.getItem(selectedUser.value);

                const userId = user._id;
                const tplId = selectedModel.value;
                const name = infraName.value;
                const result = await Infras.deployForUser(userId, tplId, name);
                console.log(`-> Add Infra ${name} to user ${user.lastName} ${user.firstName}`);
                emits("confirm");
            }
            else{
                let group = await Groups.getItem(selectedGroup.value);

                let count = 1;
                for(let userId of group.members){
                    const tplId = selectedModel.value;
                    const name = infraName.value;
                    const result = await Infras.deployForUser(userId._id, tplId, name+count);
                    console.log(`-> Add Infra ${name} to user ${userId.lastName} ${userId.firstName}`);
                    count++;
                }

                emits("confirm");
            }

        }
        catch(e){
            alert(e);
            emits("close");
        }
    }


</script>