import axios from "axios";
const ENDPOINT = "/api/users";

export async function getItem(oid)  {
    try{
        const ITEM = await (await axios.get(`${ENDPOINT}/${oid}`)).data;
        return ITEM;
    }
    catch(e){
        alert(e.message);
        return undefined;
    }
} 

export async function getItems(filters)  {

    try{
        const ITEMS = await (await axios.get(`${ENDPOINT}?limit=999${ (filters !== undefined) ? `&fields=${filters}`:"" }`)).data;
        return ITEMS;
    }
    catch(e){
        alert(e.message);
        return [];
    }

}

export async function updateItem(item) {

    return {};
}

export async function deleteItem(oid) {

    return undefined;
}

