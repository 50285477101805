<template>  
    <div>
        <h4 class="mb-3">
            Infrastructures
            <div class="btn-group float-end" role="group" aria-label="Basic example">
                <button class="btn" @click="showInfraDeploy = true"><i class="bi bi-plus me-1"></i> Deployer</button>
                <button class="btn" v-if="(selectedInfras.length === 0)" @click="selectedInfras = infrasFiltered.map( i => i._id)"><i class="bi bi-square me-1"></i> Tous/Aucun</button>
                <button class="btn" v-else @click="selectedInfras = []"><i class="bi bi-check-square me-1"></i> Tous/Aucun</button>
            </div>
        </h4>

        <p style="text-align: justify;">
            Vous pouvez voir la liste et l'état des différentes infrastructures déployées. 
            Il est possible de configurer un déploiement pour des utilisateurs ou des groupes d'utilisateurs.
        </p>

        <div class="row">

            <div class="col">
                <div class="card mb-3">
                    <div class="card-body">
                        <h6 class="card-title text-center">Déployée(s)</h6>
                        <p class="lead text-center mb-0">
                            <span>{{ (loaded) ? infraStats.deployed:"-" }}</span>
                            <br>
                            <i class="bi bi-check-circle-fill text-success fa-2x"></i>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-3">
                    <div class="card-body">
                        <h6 class="card-title text-center">En erreur(s)</h6>
                        <p class="lead text-center mb-0">
                            <span>{{ (loaded) ? infraStats.errors:"-" }}</span>
                            <br>
                            <i class="bi bi-x-circle-fill text-danger fa-2x"></i>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-3">
                    <div class="card-body">
                        <h6 class="card-title text-center">Déploiement(s)</h6>
                        <p class="lead text-center mb-0">
                            <span>{{ (loaded) ? infraStats.deployment:"-" }}</span>
                            <br>
                            <i class="bi bi-dash-circle-fill text-muted fa-2x"></i>
                        </p>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
        
            <div class="mb-3">

                <label for="" class="form-label">
                    <h6>Légende</h6>
                </label>

                <div class="alert alert-secondary">
                    <small>

                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="0" id="flexCheckDefault" checked v-model="stateFilters">
                            <label class="form-check-label" for="flexCheckDefault">
                                <i class="bi bi-circle text-muted"></i> Commandée 
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked1" checked v-model="stateFilters">
                            <label class="form-check-label" for="flexCheckChecked1">
                                <i class="bi bi-circle-half text-muted"></i> En construction 
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="7" id="flexCheckChecked2" checked v-model="stateFilters">
                            <label class="form-check-label" for="flexCheckChecked2">
                                <i class="bi bi-wrench-adjustable-circle-fill text-muted"></i> Maj
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="4" id="flexCheckChecked6" checked v-model="stateFilters">
                            <label class="form-check-label" for="flexCheckChecked6">
                                <i class="bi bi-circle-fill text-warning"></i> Extinction 
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="5" id="flexCheckChecked3" checked v-model="stateFilters">
                            <label class="form-check-label" for="flexCheckChecked3">
                                <i class="bi bi-circle-fill text-muted"></i> Stoppée 
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="2" id="flexCheckChecked4" checked v-model="stateFilters">
                            <label class="form-check-label" for="flexCheckChecked4">
                                <i class="bi bi-circle-fill text-primary"></i> Démarre 
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="3" id="flexCheckChecked5" checked v-model="stateFilters">
                            <label class="form-check-label" for="flexCheckChecked5">
                                <i class="bi bi-circle-fill text-success"></i> Démarrée 
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="6" id="flexCheckChecked7" checked v-model="stateFilters">
                            <label class="form-check-label" for="flexCheckChecked7">
                                <i class="bi bi-circle-fill text-danger"></i> Supression 
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="8" id="flexCheckChecked8" checked v-model="stateFilters">
                            <label class="form-check-label" for="flexCheckChecked8">
                                <i class="bi bi-x-circle-fill text-danger"></i> Vérouillée 
                            </label>
                        </div>

                    </small>
                </div>

                <!-- Filters -->
                <div class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label for="" class="form-label"><h6>Groupe</h6></label>
                            <select name="" id="" class="form-select" v-model="filterGroup">
                                <option :value="null">-- Tous --</option>
                                <option v-for="group in groups" :key="group._id" :value="group">{{group.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <label for="" class="form-label">
                    <h6>Liste {{ (infrasFiltered.length > 0) ? "(" + infrasFiltered.length + ")" : null }}</h6>
                </label>

                <div v-if="loaded">
                    <ul class="list-group" v-if="(loaded && infrasFiltered.length > 0)">
                        <li class="list-group-item list-group-item-action" v-for="infra in infrasFiltered" :key="infra._id">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input role="button" class="form-check-input me-2" type="checkbox" :value="infra._id" v-model="selectedInfras">
                                        </td>
                                        <td>
                                            <i class="bi me-4" :class="{
                                                    'bi-circle-fill text-muted': infra.status === Infras.InfraStatusCode.STOPPED,
                                                    'bi-circle-fill text-primary': infra.status === Infras.InfraStatusCode.STARTING,
                                                    'bi-circle-fill text-success': infra.status === Infras.InfraStatusCode.STARTED,
                                                    'bi-circle-fill text-warning': infra.status === Infras.InfraStatusCode.STOPPING,
                                                    'bi-circle-fill text-danger': infra.status === Infras.InfraStatusCode.DELETING,
                                                    'bi-x-circle-fill text-danger': infra.status === Infras.InfraStatusCode.LOCKED,
                                                    'bi-wrench-adjustable-circle-fill text-muted': infra.status === Infras.InfraStatusCode.UPDATING,
                                                    'bi-circle text-muted': infra.status === Infras.InfraStatusCode.DEFINED,
                                                    'bi-circle-half text-muted': infra.status === Infras.InfraStatusCode.PENDING
                                                }"></i>
                                        </td>
                                        <td class="w-100">
                                            {{infra.name}} - <small class="text-muted">{{infra.user_id.firstName}} {{infra.user_id.lastName}} ({{infra.user_id.email}})</small>
                                        </td>
                                        <td>
                                            <!-- Display the time remaining for this month on the infra -->
                                            <div class="progress me-2" style="width: 100px;">
                                                <div class="progress-bar" :style="'width: '+ ( infra.user_id.runningHours / (infra.user_id.quota_id.maxHours*infra.user_id.quota_id.maxInstances) )*100+'%;'" role="progressbar" :aria-valuenow="( infra.user_id.runningHours / (infra.user_id.quota_id.maxHours*infra.user_id.quota_id.maxInstances) )*100" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <div class="btn-group btn-group-sm" v-if="![Infras.InfraStatusCode.DEFINED,Infras.InfraStatusCode.PENDING,Infras.InfraStatusCode.DELETING].includes(infra.status)">
                                                <button class="btn btn-outline-secondary" @click="currentInfra = infra; showStopConfirm = true" :disabled="Infras.isStopped(infra) || Infras.isStopping(infra) || Infras.isStarting(infra) || Infras.isLocked(infra)" title="Arrêt"><i class="bi bi-stop-circle"></i></button>
                                                <button class="btn btn-outline-secondary" @click="currentInfra = infra; showStartConfirm = true" :disabled="Infras.isStarted(infra) || Infras.isStopping(infra) ||  Infras.isStarting(infra) || Infras.isLocked(infra)" title="Démarrer"><i class="bi bi-play-circle"></i></button>
                                                <button class="btn btn-outline-secondary" @click="currentInfra = infra; showFixConfirm = true" :disabled="!Infras.isLocked(infra)" title="Réparer"><i class="bi bi-bandaid"></i></button>
                                                <button class="btn btn-outline-danger" @click="currentInfra = infra; showDeleteConfirm = true" :disabled="Infras.isLocked(infra)" title="Supprimer"><i class="bi bi-trash"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                    </ul>
                    <div class="alert alert-info text-center" v-else-if="(loaded && infras.length === 0)">
                        <i class="bi bi-exclamation-circle-fill text-primary"></i> Pas d'infrastructures, cliquez sur "<i class="bi bi-plus"></i> Déployer"
                    </div>
                    <div class="alert alert-info text-center" v-else-if="(loaded && infras.length > 0 && infrasFiltered.length === 0)">
                        <i class="bi bi-exclamation-circle-fill text-primary"></i> Pas d'infrastructures avec le ou les filtres selectionés
                    </div>
                    

                    <ul class="pagination mt-3 float-end" v-if="(loaded && infras.length > 0)">
                        <li class="page-item disabled"><a class="page-link" href="#">&lt;</a></li>
                        <li class="page-item disabled"><a class="page-link">-- / --</a></li>
                        <li class="page-item disabled"><a class="page-link" href="#">&gt;</a></li>
                    </ul>
                
                    <select class="form-select mt-3" aria-label="Default select example" :disabled="selectedInfras.length === 0" style="width: 200px;" @change="(showGroupActionConfirm = true)" v-model="groupActionValue"  v-if="(loaded && infras.length > 0)">
                        <option selected value="">Action de groupe</option>
                        <option value="stop">Arrêter</option>
                        <option value="start">Démarrer</option>
                        <option value="delete">Supprimer</option>
                        <option value="lock">Vérouiller</option>
                    </select>

                </div>
                <div v-else>
                    <span class="spinner spinner-border spinner-border-sm me-2"></span>Chargement...
                </div>

                
            </div>

        </div>

    </div>

    <!-- Delete Modal -->
    <ConfirmBox modal-id="DeleteConfirm"
            :show="showDeleteConfirm" 
            message="Êtes vous certain de vouloir supprimer cette infra ?"
            typeOf="danger" 
            no="Non"
            @close="showDeleteConfirm = false"
            @decline="showDeleteConfirm = false"
            @confirm="deleteInfra()" />

    <!-- Start Modal -->
    <ConfirmBox modal-id="StartConfirm"
            :show="showStartConfirm" 
            message="Êtes vous certain de vouloir démarrer cette infra ?"
            typeOf="question" 
            no="Non"
            @close="showStartConfirm = false"
            @decline="showStartConfirm = false"
            @confirm="startInfra()" />

    <!-- Stop Modal -->
    <ConfirmBox modal-id="StopConfirm"
            :show="showStopConfirm" 
            message="Êtes vous certain de vouloir stopper cette infra ?"
            typeOf="question" 
            no="Non"
            @close="showStopConfirm = false"
            @decline="showStopConfirm = false"
            @confirm="stopInfra()" />

    <!-- Fix Modal -->
    <ConfirmBox modal-id="FixConfirm"
            :show="showFixConfirm" 
            message="Êtes vous certain de tenter de réparer cette infra ? Ceci entraînera un nouveau déploiement ainsi que la supression de toutes les ressource existantes."
            typeOf="danger" 
            no="Non"
            @close="showFixConfirm = false"
            @decline="showFixConfirm = false"
            @confirm="fixInfra()" />

    <ConfirmBox modal-id="GroupActionConfirm"
            :show="showGroupActionConfirm" 
            :message="`Êtes vous certain de vouloir réaliser l'action de groupe <${groupActionValue.toUpperCase()}> sur ces ${selectedInfras.length} infra(s) ? ${selectedInfras.join(', ')}`"
            typeOf="danger" 
            no="Non"
            @close="showGroupActionConfirm = false"
            @decline="showGroupActionConfirm = false"
            @confirm="groupAction()" />

    <!-- For the deployment of the infra -->
    <InfraDeploy modal-id="InfraDeploy"
            @close="showInfraDeploy = false"
            @decline="showInfraDeploy = false"
            @confirm=" async () => { showInfraDeploy = false; await loadInfras(); }"
            :show="showInfraDeploy" />

    <ProgressBar modal-id="progressBarRepair" 
            :show="showProgressRepair"
            :percent="progressPercentRepair"
            :message="progressMessageRepair"
            @close="progressFinished()" />

</template>

<script setup>

    import {onMounted, onUnmounted, ref, computed, defineProps} from "vue"

    import * as Infras from "../../services/Infras.js";
    import * as Users from "../../services/Users.js";
    import * as Groups from "../../services/Groups.js";
    import ConfirmBox from "./components/generics/ConfirmBox.vue";
    import InfraDeploy from "./components/InfraDeploy.vue";
    import {InfraStatusCode} from "../../services/Infras.js";
    import ProgressBar from "./components/generics/ProgressBar.vue";
    import axios from "axios";

    let props = defineProps(["userInfo"]);
    
    // Store the interval of this view
    let interval = ref(0);

    // Vars for the confirm boxes
    let showStopConfirm = ref(false);
    let showStartConfirm = ref(false);
    let showFixConfirm = ref(false);
    let showDeleteConfirm = ref(false);
    let showInfraDeploy = ref(false);
    let showGroupActionConfirm = ref(false);
    let showProgressRepair = ref(false);

    // The loaded switch
    let loaded = ref(false);
    
    // Represent the stats of the infras
    let infraStats = ref({
        deployed: 0,
        errors: 0,
        deployment: 0,
        total: 0
    });

    // For filter by groups
    let groups = ref([]);

    // The listed infras
    let infras = ref([]);

    // Filter the infras by status and by groups
    let filterGroup = ref(null);
    let infrasFiltered = computed( () => {

        // Filtered = the actual state of infras
        let filtered = infras.value;

        // Filter by groups first ?
        if(filterGroup.value !== null){
            let membersIds = filterGroup.value.members.map( m => m._id)
            filtered = infras.value.filter( i => membersIds.indexOf(i.user_id._id) > -1 );
        }

        // Filter by status 
        filtered = filtered.filter( i => stateFilters.value.indexOf(i.status.toString()) > -1);
        
        // Return the filtered array of infras
        return filtered;
    });

    // The selected infra when action is selected (on the buttons of his line)
    let currentInfra = ref({});

    let stateFilters = ref(["0","1","2","3","4","5","6","7","8"]);

    // Selected infra for checkboxes
    let selectedInfras = ref([]);
    let groupActionValue = ref("");

    // For the repair progress bar
    let progressPercentRepair = ref(0);
    let progressMessageRepair = ref("");

    /**
     * Load the infras and users info
     */
    async function loadInfras() {
        let infrasApi = await Infras.getItems();
        let users = await Users.getItems();
        for(let infra of infrasApi) {
            let userInfra = users.filter( u => u._id == infra.user_id );
            if( userInfra.length == 1 ){
                infra.user_id = userInfra[0];
            }
        }

        infras.value = infrasApi;
    }

    async function startInfra(){
        showStartConfirm.value = false;
        await Infras.startItem(currentInfra.value._id);
        currentInfra.value.status = Infras.InfraStatusCode.STARTING;
    }

    async function stopInfra(){
        showStopConfirm.value = false;
        await Infras.stopItem(currentInfra.value._id);
        currentInfra.value.status = Infras.InfraStatusCode.STOPPING;
    }

    async function fixInfra(){
        showFixConfirm.value = false;
        progressMessageRepair.value = "Recherche et suppression d'instances...";
        showProgressRepair.value = true;

        let step = await Infras.fixItemStep(currentInfra.value._id, 'instances');
        console.log(step);
        // First remove Instances
        while(  step.instances > 0 ){
            progressMessageRepair.value = `Recherche et suppression d'instances... ${step.instances} instance(s) restante(s)`;
            await ( new Promise( (resolve) => setTimeout( () => resolve(), 5000 )) );
            step = await Infras.fixItemStep(currentInfra.value._id, 'instances');
        }
        progressPercentRepair.value = 33;

        // Remove all networks parts
        progressMessageRepair.value = `Recherche et suppression de composants réseaux...`;
        await Infras.fixItemStep(currentInfra.value._id, 'networks');
        await ( new Promise( (resolve) => setTimeout( () => resolve(), 5000 )) );
        progressPercentRepair.value = 66;
        
        // Remove the VPC and start rebuild
        progressMessageRepair.value = `Recherche et suppression du data center et reconstruction...`;
        await Infras.fixItemStep(currentInfra.value._id, 'vpc');
        await ( new Promise( (resolve) => setTimeout( () => resolve(), 5000 )) );
        progressPercentRepair.value = 100;
        // await Infras.fixItem(currentInfra.value._id);
    }

    async function deleteInfra(){
        showDeleteConfirm.value = false;
        await Infras.deleteItem(currentInfra.value._id);
        currentInfra.value.status = Infras.InfraStatusCode.DELETING;
    }

    async function sleep(ms){
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    async function groupAction(){
        showGroupActionConfirm.value = false;

        try{
          switch(groupActionValue.value){
            case "stop":
              for(let infraID of selectedInfras.value ){
                let infraItem = ( infras.value.filter( i => infraID === i._id).length === 1) ? infras.value.filter( i => infraID === i._id)[0] : undefined;
                if(!infraItem) return;
                if(infraItem.status === InfraStatusCode.STOPPED ) return;
                await Infras.stopItem(infraItem._id);
                console.log("-> Stopping infra : " + infraID);
                // Wait 0.5 sec to avoid to submerge the API Rate limit
                await sleep(500);
              }
              break;
            case "start":
              for(let infraID of selectedInfras.value ){
                console.log( infras.value.filter( i => (infraID === i._id) ) );
                let infraItem = ( infras.value.filter( i => infraID === i._id).length === 1) ? infras.value.filter( i => infraID === i._id)[0] : undefined;
                if(!infraItem) return;
                if(infraItem.status === InfraStatusCode.STARTED ) return;
                await Infras.startItem(infraItem._id);                
                console.log("-> Starting infra : " + infraID);
                // Wait 0.5 sec to avoid to submerge the API Rate limit
                await sleep(500);
              }
              break;
            case "delete":
              for(let infraID of selectedInfras.value ){
                let infraItem = ( infras.value.filter( i => infraID === i._id).length === 1) ? infras.value.filter( i => infraID === i._id)[0] : undefined;
                if(!infraItem) return;
                if(infraItem.status === InfraStatusCode.STARTED || infraItem.status === InfraStatusCode.STARTING || infraItem.status === InfraStatusCode.STOPPING ) return;
                await Infras.deleteItem(infraItem._id);
                console.log("-> Deleting infra : " + infraID);
                // Wait 0.5 sec to avoid to submerge the API Rate limit
                await sleep(500);
              }
              break;
            case "lock":
              for(let infra of selectedInfras.value){
                let infraItem = await Infras.getItem(infra);
                infraItem.status = InfraStatusCode.LOCKED;
                await Infras.updateItem(infraItem);
                console.log("-> Updating infra status to LOCKED state : " + infraItem.status);
                // Wait 0.5 sec to avoid to submerge the API Rate limit
                await sleep(500);
              }
              break;
            default:
              break;
          }
          groupActionValue.value = "";
          await loadInfras();
        }
        catch(e){
          console.log(e);
          alert(e.message);
        }

    }

    /**
     * When the complete repair action is finished
     */
    async function progressFinished(){
        showProgressRepair.value = false;
    }
    
    /**
     * When the component is fully loaded
     */
    onMounted( async () => {

        // Load groups
        groups.value = await Groups.getItems();

        // Load infras
        infraStats.value = await Infras.getStats();
        await loadInfras();

        // Set an interval to refresh infra each 10sec
        interval.value = setInterval( async () => {
            infraStats.value = await Infras.getStats();
            await loadInfras();
        }, 10000);
    
        // Mark page as loaded
        loaded.value = true;
    });

    onUnmounted( () => {
        clearInterval(interval.value);
    })
    

</script>