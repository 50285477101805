<template>
    <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="visible">
                <div class="modal-header">
                    <h6 class="modal-title"><i class="me-2" :class="{bi: typeOf !== null, 'bi-info-circle-fill text-primary' : typeOf === 'info', 'bi-question-circle-fill text-primary' : typeOf === 'question', 'bi-exclamation-circle-fill text-danger' : typeOf === 'danger' }"></i>{{(title) ? title : "Confirmation"}}</h6>
                    <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <p class="modal-text mb-0">{{message}}</p>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm" @click.prevent="decline()"><i class="bi bi-x me-2 text-danger"></i>{{(no) ? no : "Non"}}</button>
                    <button type="button" v-if="!messageOnly" class="btn btn-sm" @click.prevent="confirm()"><i class="bi bi-check text-success me-2"></i>{{(yes) ? yes : "Oui"}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, ref, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";

    let props = defineProps(["modalId","show","message","yes","no","title","typeOf","messageOnly"]);
    let emits = defineEmits(["close","confirm","decline"]);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    onMounted( () => {
        
    });

    function close(){
        emits("close");
    }

    function confirm(){
        emits("confirm");
    }
    
    function decline(){
        emits("decline");
    }


</script>