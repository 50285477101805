<template>
    <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="visible">

                <div class="modal-body">
                    <p v-if="message">{{message}}</p>
                    <div class="progress">
                        <div class="progress-bar" :class="{'bg-success': percent >= 100}"  role="progressbar" v-if="!isUndefined" :style="'width: '+percent+'%'"></div>
                        <div class="progress-bar w-100" :class="{'bg-success': percent >= 100, 'progress-bar-striped progress-bar-animated': percent < 100}" role="progressbar" v-else></div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";

    let props = defineProps(["modalId","show","message","percent","isUndefined"]);
    let emits = defineEmits(["close"]);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
            setIntervalCheck()
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    let interval = null
    onMounted( () => {
        
    })

    function setIntervalCheck(){
        interval = setInterval( () => {
            if(props.percent >= 100){
                clearInterval(interval);
                close();
            }
        },5000)
    }

    function close(){
        emits("close");
    }

</script>