import axios from "axios";
const ENDPOINT = "/api/infras";

export let InfraStatusCode = {
    DEFINED: 0,    // 0
    PENDING: 1,    // 1
    STARTING: 2,   // 2
    STARTED: 3,    // 3
    STOPPING: 4,   // 4
    STOPPED: 5,    // 5
    DELETING: 6,   // 6
    UPDATING: 7,   // 7
    LOCKED: 8      // 8
}

export async function getItem(oid)  {
    try{
        const ITEM = await (await axios.get(`${ENDPOINT}/${oid}`)).data;
        return ITEM;
    }
    catch(e){
        alert(e.message);
        return undefined;
    }
} 

export async function getItems(filters)  {

    try{
        const ITEMS = await (await axios.get(`${ENDPOINT}?limit=999${ (filters !== undefined) ? `&fields=${filters}`:"" }`)).data;
        return ITEMS;
    }
    catch(e){
        alert(e.message);
        return [];
    }

}

export async function updateItem(item) {

    try{
        const UPDATE = await (await axios.put(`${ENDPOINT}/${item._id}`, item)).data;
        return UPDATE;
    }
    catch(e){
        alert(e.message);
        return [];
    }
}

export async function deleteItem(oid) {

    try{
        let result = await axios.delete(`${ENDPOINT}/${oid}`).data;
        return result;
    }
    catch(e){
        alert(e.message||e);
        return undefined;
    }

}

export async function startItem(oid) {

    try{
        let result = await axios.post(`${ENDPOINT}/${oid}/start`).data;
        return result;
    }
    catch(e){
        alert(e.message||e);
        return undefined;
    }

}

export async function stopItem(oid) {

    try{
        let result = await axios.post(`${ENDPOINT}/${oid}/stop`).data;
        return result;
    }
    catch(e){
        alert(e.message||e);
        return undefined;
    }

}

/**
 * Old method but works
 * @param {*} oid 
 * @returns 
 */
export async function fixItem(oid) {

    try{
        let result = await axios.post(`${ENDPOINT}/${oid}/repair`).data;
        return result;
    }
    catch(e){
        alert(e.message||e);
        return undefined;
    }

}

/**
 * New methods, step by step
 * @param {*} oid 
 * @returns 
 */
export async function fixItemStep(oid, step = 'instances') {

    try{
        let result = (await axios.delete(`${ENDPOINT}/${oid}/repair/${step}`)).data;
        return result;
    }
    catch(e){
        alert(e.message||e);
        return undefined;
    }

}

export async function deployForUser(userId, tplId, infraName) {

    try{
        let result = await axios.post(`${ENDPOINT}/by-moderator`, {
            user_id: userId,
            terra_id: tplId,
            infra_name: infraName
        }).data;

        return result;
    }
    catch(e){
        alert(e.message);
        return undefined;
    }

}

export async function getStats() {
    try{

        let deployed = 0;
        let errors = 0;
        let deployment = 0;
        let total = 0;

        const INFRAS = await getItems();
        
        total = INFRAS.length;

        for(let infra of INFRAS){
            if( [InfraStatusCode.STARTED, InfraStatusCode.STARTING, InfraStatusCode.STOPPED, InfraStatusCode.STOPPING].includes(infra.status) ) deployed++;
            if( [InfraStatusCode.DEFINED, InfraStatusCode.PENDING, InfraStatusCode.UPDATING, InfraStatusCode.DELETING].includes(infra.status) ) deployment++;
            if( [InfraStatusCode.LOCKED].includes(infra.status) ) errors++;
        }

        return {
            deployed,
            errors,
            deployment,
            total
        };
        
    }
    catch(e){
        alert(e.message);
        return undefined;
    }
}

export function isStopped(infra) {
    return infra.status == InfraStatusCode.STOPPED;
}

export function isStarted(infra) {
    return infra.status == InfraStatusCode.STARTED;
}

export function isStopping(infra) {
    return infra.status == InfraStatusCode.STOPPING;
}

export function isStarting(infra) {
    return infra.status == InfraStatusCode.STARTING;
}

export function isDeleting(infra) {
    return infra.status == InfraStatusCode.DELETING;
}

export function isUpdating(infra) {
    return infra.status == InfraStatusCode.UPDATING;
}

export function isLocked(infra) {
    return infra.status == InfraStatusCode.LOCKED;
}